<template>
  <div>
    <div class="content_block">
      <query-form label-width="100px" @search="handleSearch" @reset="handleReset">
        <el-form-item label="医院名称：">
          <el-input v-model="query.customerName" placeholder="请输入医院名称"></el-input>
        </el-form-item>
        <el-form-item label="医院联系人：">
          <el-input v-model="query.name" placeholder="请输入医院联系人"></el-input>
        </el-form-item>
        <el-form-item label="医工账号：">
          <el-input v-model="query.phone" placeholder="请输入医工账号"></el-input>
        </el-form-item>
      </query-form>
    </div>
    <table-with-pagination
      :tableData="contactList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </div>
</template>
<script>
import ContactApi, {updateAccountStatus} from '@/api/contact';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';

export default {
  name: 'CONTACT_INDEX',
  mixins: [
    TablePaginationMixin,
  ],
  data () {
    return {
      columnList: [
        {
          tooltip: true,
          label: '医院名称',
          minWidth: '160px',
          prop: 'customerName',
        },
        {
          tooltip: true,
          label: '医院联系人',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '医院联系人电话',
          minWidth: '150px',
          prop: 'phone',
        },
        {
          tooltip: true,
          label: '医工账号',
          minWidth: '150px',
          prop: 'phone',
        },
        {
          tooltip: true,
          label: '开通状态',
          minWidth: '150px',
          prop: 'statusDesc',
        },
        {
          tooltip: true,
          label: '开通人',
          minWidth: '150px',
          prop: 'openUserName',
        },
        {
          tooltip: true,
          label: '开通时间',
          minWidth: '150px',
          prop: 'openTime',
          format: this.$options.filters['date-format'],
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            row.status === '1' && actions.push(<span v-auth="hospitalAccount:open" class='cursor-pointer text-primary ml-1 table-action' onClick={this.handleActionClick.bind(this, row, '2')}>开通</span>);
            row.status === '2' && actions.push(<span v-auth="hospitalAccount:close" class='cursor-pointer text-primary ml-1 table-action' onClick={this.handleActionClick.bind(this, row, '3')}>关闭</span>);
            row.status === '3' && actions.push(<span v-auth="hospitalAccount:activate" class='cursor-pointer text-primary ml-1 table-action' onClick={this.handleActionClick.bind(this, row, '2')}>激活</span>);
            return <div>{...actions}</div>;
          },
        },
      ],
      query: {
        customerName: '',
        name: '',
        phone: '',
      },
      contactList: [],
      type: '',
    };
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        customerName: '',
        name: '',
        phone: '',
      };
      this.handleSearch();
    },
    getTableList () {
      ContactApi.getContactList({...this.query, ...this.pagination, pageNum: this.pagination.currentPage}).then((res) =>{
        this.contactList = res.body.records;
        this.pagination.total = res.body.total;
      });
    },
    handleActionClick (row, status) {
      if (row.status === '1') {
        this.$confirm('确认开通当前账套吗?', '开通小紫医工端账号链接', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          updateAccountStatus({
            id: row.id,
            status: status,
          }).then(() => {
            this.$message.success('账套已开通');
            this.handleSearch();
          });
        });
      }
      if (row.status === '2') {
        this.$confirm('点击确认后，将会断开小紫医工账号与我方的关联，用户在小紫医工系统中无法再查看我方信息。', '关闭小紫医工端账号链接', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          updateAccountStatus({
            id: row.id,
            status: status,
          }).then(() => {
            this.$message.success('账套已关闭');
            this.handleSearch();
          });
        });
      }
      if (row.status === '3') {
        this.$confirm('点击确认后，将会激活小紫医工账号，并会以短信形式通知对方。用户可以在微信小程序搜索“小紫医工”后进行登录', '激活小紫医工端账号', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        }).then(() => {
          updateAccountStatus({
            id: row.id,
            status: status,
          }).then(() => {
            this.$message.success('账套已激活');
            this.handleSearch();
          });
        });
      }
    },
  },
};
</script>
